window.sectionTracked = []

window.trackSection = (sections) =>{
  const currentScrollPosition = window.scrollY; 
  sections.forEach(function (section) {
    if(!sectionTracked.includes(section)) {
      const elementOffsetTop = document.getElementById(section).offsetTop

      if ( currentScrollPosition > elementOffsetTop){
        let xhr = new XMLHttpRequest()
        xhr.open('POST', "/qr/client/sessions/track", true)
        xhr.setRequestHeader('Content-type', 'application/json; charset=UTF-8')
        xhr.send(JSON.stringify({name: 'scroll_to_' + section}));

        sectionTracked.push(section);
      }
    }
  });
}